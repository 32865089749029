
/* AC_S_NEWS - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="news"] {

    // items
    $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

    $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
    $items-content-over-image: true;
    $items-have-padding: true;
    $items-have-border-dividers: false;
    $items-have-border-below: true;
    $items-hide-border-below-on-last-row: false;
    $items-border-radius: 0; 
    $items-first-item-full-width: false;
    $items-first-item-full-width-height-multiplier: 1.5;
    $items-on-small-two-items-a-row: true;
    $item-background: none;
    $item-background-hover: none;

    // columns
    $items-columns-enabled: false;
    $items-columns-breakpoint: 800px; 
    $items-columns-height: 400px; 
    $items-columns-hide-last-one-below-breakpoint: false;
    $items-columns-icon-image-combination: true;

    // item
    $item-margin-bottom: 1px; // 1.4em
    $item-border: $default-border; // defines the appearance, not the display
    $item-shadow-enabled: false;

    // image
    $item-image-container-enabled: true;
    $item-image-enabled: true;
    $item-image-height: 300px;
    $item-image-initial-fade: 1;
    $item-image-hover-fade: .8;
    $item-image-gradient-before: true; // when item-content-over-image
    $item-image-gradient-color: $brand-black;
    $item-image-gradient-before-position: 'bottom'; // top or bottom
    $item-image-background-color: $default-background-dark;
    $item-image-background-color-hover: $default-background-dark;
    $item-image-border-radius: 0; 


    // content
    $item-content-padding: 0; // $default-padding; 
    $item-content-background: none;
    $item-content-background-hover: none;
    $item-content-align: 'lb'; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
    $item-content-copy-padding: 2rem 4rem 2rem 2rem; 
    $item-content-copy-align: 'left'; // left or center

    $item-content-icon-enabled: false;
    $item-content-icon-centered: true;
    $item-content-icon-color: rgba($default-icon-color-dark, 1);
    $item-content-icon-hover-color: rgba($default-icon-color-light, .4);
    $item-content-icon-size: 80px;
    $item-content-icon-padding: 20px;
    $item-content-icon-margin: 0 0 20px 0;
    $item-content-icon-background: red;
    $item-content-icon-background-hover: blue;
    $item-content-icon-round: true;
    
    $item-content-title-enabled: true; 
    $item-content-title-color: $default-text-color;
    $item-content-title-hover-color: $default-text-color;

    $item-content-copy-above-enabled: true;    
    $item-content-copy-above-color: $default-text-color;
    $item-content-copy-above-hover-color: $default-text-color;

    $item-content-copy-below-enabled: true;    
    $item-content-copy-below-color: $default-text-color;
    $item-content-copy-below-hover-color: $default-text-color;

    // icon
    $item-show-icon: true;
    $item-icon-color: $default-text-color;
    $item-icon-hover-color: $default-text-color;
    $item-icon-size: 1.8em;
    $item-icon-position: 'rb'; // lt, lb, rt, rb
    $item-icon-inset-left-right: 2rem; 
    $item-icon-inset-top-bottom: 2rem; 
    

    // list style on small | image left and content right | all background will be reset | content icon is set absolute
    $item-list-style-on-small: true; 
    $item-list-style-image-width: 80px;
    $item-list-style-content-icon-size: 60px; 
    $item-list-style-content-icon-padding: 14px; 
    $item-list-style-content-icon-background: $default-background-light; 
    $item-list-style-content-icon-color: $default-text-color;
    $item-list-style-content-title-color: $default-text-color;
    $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-below-color: rgba($default-text-color, 1);


    @import '../ac_s__items-globals/__item_globals';
    

    /*--------------------------------------------------------------------------------------------*/
    
    .ac_container {
        padding: 0 $default-padding / 2;
    }

    .ac_item {
        margin-bottom: 0;
        padding: 0 $default-padding / 2!important;
        .ac_item_container{
            border: 1px solid $brand-light-gray;
            border-radius: $default-border-radius;
        }
        .ac_item_content_copy{
            background: $brand-white;
            @include breakpoint(small only) { 
                padding: 0 20px 0 100px;
            }
        }
    }
    
    .ac_item_image-container {
        @include breakpoint(small only) { 
            &:before {
                display: none;
            }
        }
    }
    .ac_item_image {
        @include breakpoint(small only) { 
            opacity: 1;
        }
    }

    .ac_item_content_copy-above {
        text-transform: uppercase;
        font-size: 85%;
        letter-spacing: 1px;
        transition: color .3s ease;
    }
    .ac_item_content_title {
        padding: 10px 0;
        line-height: 1.2;
        @include font-headings-bold;
        font-size: 120%;
        transition: color .3s ease;
    }
    .ac_item_content_copy-below {
        text-transform: uppercase;
        font-size: 85%;
        letter-spacing: 1px;
        transition: color .3s ease;
        @include breakpoint(medium-portrait) { 
            position: absolute;
            bottom: $default-padding;
        }
    }

    .ac_item {
        padding: 0 1px;
        @include breakpoint(small only) {
            margin-bottom: $default-padding;
        }
    }

    .ac_item{
        .ac_item_icon{
            svg{
                transition: all .3s;
                stroke: $brand-secondary!important;
                fill: $brand-secondary!important;
                stroke-width: 2px;
                transform: translateX(0);
            }
        }
        &:hover{
            .ac_item_icon{
                svg{
                    stroke: $brand-secondary!important;
                    fill: $brand-secondary!important;
                    transform: translateX(2px);
                }
            }
        }
    }

    .ac_item_icon {
        @include breakpoint(small only) { 
            bottom: .6rem;
            right: .6rem;
            svg {
                fill: $default-text-color;
            }
        }
    }


} // end scope