/* VARIABLES
--------------------------------------------------------------------------------------------*/

/*
    BRAND COLORS
*/

$brand-primary: #dd0935; 
$brand-primary-lighter: lighten($brand-primary, 10%); 
$brand-primary-darker: darken($brand-primary, 10%); 

$brand-secondary: #00a297; 
$brand-secondary-darker: darken($brand-secondary, 10%); 

$brand-tertiary: #e6e6e6;
$brand-tertiary-darker: darken($brand-tertiary, 10%);

$brand-white: #ffffff;
$brand-black: #222; 
$brand-darkest-gray: #33302d;
$brand-dark-gray: #666666;
$brand-gray: #aaaaaa;
$brand-light-gray: #e6e6e6;
$brand-lightest-gray: #EEEEEE;

$brand-error: red;

// for adjustment classes generation
$brand-color-list:  (
    primary: $brand-primary,
    primary_darker: $brand-primary-darker,
    secondary: $brand-secondary,
    secondary_darker: $brand-secondary-darker, 
    tertiary: $brand-tertiary, 
    tertiary_darker: $brand-tertiary-darker,
    black: $brand-black, 
    white: $brand-white, 
    light-gray: $brand-light-gray,
    lightest-gray: $brand-lightest-gray
);


$brand-background-primary: linear-gradient(90deg, $brand-primary, $brand-secondary);
$brand-background-secondary: linear-gradient(90deg, $brand-secondary, $brand-secondary-darker);
$brand-background-tertiary: linear-gradient(-135deg, $brand-tertiary-darker, $brand-tertiary);

$brand-background-primary-reversed: linear-gradient(270deg, $brand-primary, $brand-secondary);
$brand-background-primary-reversed-darken: linear-gradient(270deg, $brand-primary-darker, $brand-secondary-darker);
$brand-background-secondary-reversed: linear-gradient(270deg, $brand-secondary, $brand-secondary-darker);
$brand-background-tertiary-reversed: linear-gradient(135deg, $brand-tertiary-darker, $brand-tertiary);

// for adjustment classes generation
$brand-gradient-list:  (
    primary: $brand-background-primary,
    secondary: $brand-background-secondary,
    tertiary: $brand-background-tertiary, 
);

/*
    DEFAULTS
*/

$default-padding: 20px;
$default-border-radius: 4px;// 6px
$default-transition-speed: .3s ease;
$default-box-shadow: 5px 5px 10px rgba(black, .1);

$default-text-color: $brand-darkest-gray;
$default-text-color-light: $brand-white;
$default-text-color-dark: $brand-primary-darker;

$default-icon-color: $brand-primary;
$default-icon-color-light: $brand-white;
$default-icon-color-dark: $brand-primary;

$default-background-light: $brand-white;
$default-background-dark: $brand-black;

$default-border-color: rgba($brand-dark-gray, .1);  
$default-border: solid 1px $default-border-color;


/*
    OTHER VARIABLES
*/

// SVG
$svg-icon-set-type: 'fill'; // stroke or fill. 

// MAX CONTENT WIDTH
$max-content-600: 600px;
$max-content-800: 800px;
$max-content-1000: 1000px;
$max-content-1100: 1100px;
$max-content-1200: 1200px;
$max-content-1400: 1400px;
$max-content-1600: 1600px;


/*
    LIST FOR DEFAULT BG COLOR GENERATION
*/

$default-bg-list:  (
    default_light: $default-background-light,
    default_dark: $default-background-dark,
);


/*
    LIST FOR DEFAULT FONT COLOR GENERATION
*/

$default-text-color-list:  (
    default: $default-text-color,
    default_light: $default-text-color-light,
    default_dark: $default-text-color-dark,
);


/*
    LIST FOR DEFAULT FILL COLOR GENERATION
*/

$default-fill-color-list:  (
    default: $default-icon-color,
    default_light: $default-icon-color-light,
    default_dark: $default-icon-color-dark,
);


