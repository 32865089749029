
/* FOOTER 1 - 1
--------------------------------------------------------------------------------------------*/

[data-f-type="footer-1"] {


    // footer
    $footer-background: $brand-secondary-darker;
    $footer-content-width: $max-content-1200;
    
    // primary container
    $footer-primary-enabled: true;
    $footer-primary-background: none;
    $footer-primary-padding: $default-padding * 2 $default-padding / 2;
    $footer-primary-color: $default-text-color-light;
    $footer-primary-link-color: rgba($brand-primary, 1);
    $footer-primary-link-hover-color: rgba($brand-primary, .7);
    $footer-primary-column-1-enabled: true;
    $footer-primary-column-2-enabled: true;
    $footer-primary-column-3-enabled: true;
    $footer-primary-column-4-enabled: true;
    // width of columns above medium. Any combination is possible, as long as it adds up to 100
    $footer-primary-column-width-1: 1*.25;
    $footer-primary-column-width-2: 1*.25;
    $footer-primary-column-width-3: 1*.25;
    $footer-primary-column-width-4: 1*.25;
    $footer-primary-collapsed-hide-column-1: false;
    $footer-primary-collapsed-hide-column-2: false;
    $footer-primary-collapsed-hide-column-3: false;
    $footer-primary-collapsed-hide-column-4: false;
    $footer-primary-collapsed-align-center: true;
    
    // secondary container
    $footer-secondary-enabled: true;
    $footer-secondary-background: rgba($brand-white, .1);
    $footer-secondary-padding: $default-padding / 2 $default-padding / 2;
    $footer-secondary-color: rgba($brand-white, .5);
    $footer-secondary-link-color: rgba($brand-white, .5);
    $footer-secondary-link-hover-color: rgba($brand-white, .9);
    $footer-secondary-column-1-enabled: true;
    $footer-secondary-column-2-enabled: true;
    $footer-secondary-column-width-1: 1*.5;
    $footer-secondary-column-width-2: 1*.5;
    $footer-secondary-column-1-justify: flex-start;
    $footer-secondary-column-2-justify: flex-end;
    $footer-secondary-collapsed-align-center: true;
    
    // socials
    $footer-socials-icon-background: $brand-tertiary-darker;
    $footer-socials-icon-hover-background: $brand-white;
    $footer-socials-icon-color: $brand-white;
    $footer-socials-icon-hover-color: $brand-tertiary-darker;
    

    @import '../ac_f__footer-globals/__footer_globals';


    /*--------------------------------------------------------------------------------------------*/
    
    // globals
    @if $footer-background != transparent or $footer-background != none {
        background: $footer-background;
    }
    font-size: 90%;
    
    h2 {
        font-size: 120%;
        margin-bottom: $default-padding / 2;
        // opacity: .5;
    }

    a {
        font-weight: 500;
        color: rgba($brand-white, .8)!important;
        transition: all .3s;
    }

    a {
        &:hover{
            color: $brand-white!important;
        }
    }

    .ac_footer_bottom_bar{
        width: 100%;
        background: $brand-background-primary-reversed-darken;
        &_inner{
            max-width: 1200px;
            padding: $default-padding / 2 $default-padding;
            margin: 0 auto;
        }
    }

    .ac_footer_primary_column:nth-child(3){
        a{
            display: inline-block;
            width: 100%;
        }
        
        p:hover{
            a{
                color: $brand-white!important;
            }
        }
    }

    ul{
        margin: 0;
        li {
            list-style-type: none;
            text-transform: uppercase;
            margin-bottom: .2rem;
            font-weight: 600;
            font-size: 95%;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .ac_footer_primary_column:nth-child(3){
        .ac_footer-1_editor{
            strong {
                background: $brand-white;
                color: $brand-primary;
                width: 20px;
                font-weight: 600;
                height: 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transform: translateX(-.2rem);
            }
            a {
                display: inline;
            }
        }
    }
   
    .ac_footer_primary_column:nth-child(4){
        .ac_socials{
            display: block;
            h2{
                display: block;
            }
            a {
                display: inline-block;
                .ac_socials_link_icon{
                    background: $brand-white;
                    svg{
                        fill: $brand-primary;
                    }
                }     
                &:hover{
                    .ac_socials_link_icon{
                        background: $brand-primary;
                        svg{
                            fill: $brand-white;
                        }
                    } 
                }           
                &:nth-child(2){
                    margin-left: .4rem;
                }
            }
        }
    }

    //
    //      COLUMN
    //

    // margin fix for news items, if in second column
    .ac_footer_column {
        &:nth-child(2) {
            .ac_footer_link {
                line-height: 1.3;
                margin-bottom: $default-padding;
            }
        }
    }


    //
    //      PRIMARY
    //

    .ac_footer_primary_link {
        display: block;
    }
    .ac_footer_primary_link_copy_above {
        text-transform: uppercase;
        display: block;
        opacity: .5;
        font-size: 80%;
    }


    //
    //      SECONDARY
    //
    .ac_footer_secondary {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 90%;
    }
    .ac_footer_secondary_link {
        display: block;
    }


} // end of scope

